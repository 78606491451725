<template>
  <div>
    <v-card class="mostrar-scroll" :height="sidebarHeigth">
        <v-list>
        <v-list-item>
            <v-list-item-title>Opciones de uso</v-list-item-title>
        </v-list-item>
        <v-list-item >
            <v-list-item-icon>
                <v-icon>mdi-volume-high</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Volumen</v-list-item-title>
        </v-list-item>
        <v-list-item >
            <v-list-item-title class="mx-5">
                <v-slider v-model.number="volumenLocal" append-icon="mdi-volume-plus" prepend-icon="mdi-volume-minus" @click:append="modificarSonido(5,true)" @click:prepend="modificarSonido(-5,true)"></v-slider>
            </v-list-item-title>
        </v-list-item>
        <v-list-group no-action :value="prueba" @click="modificarCartilla(estadoCartillaFonetica?0:'')" color="primary">
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon>mdi-book-music-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                <v-list-item-title>Cartilla fonética</v-list-item-title>
                </v-list-item-content>
            </template>

            <v-list-item @click="modificarCartilla(1)" class="pl-12">
                <v-list-item-title>Con Imagen</v-list-item-title>

                <v-list-item-icon>
                    <v-icon v-if="ObjetoUnity.CartillaFonetica!=1">mdi-checkbox-blank-circle-outline</v-icon>
                    <v-icon v-if="ObjetoUnity.CartillaFonetica==1" color="green darken-2">mdi-checkbox-marked-circle</v-icon>
                </v-list-item-icon>
            </v-list-item>
            <v-list-item @click="modificarCartilla(2)" class="pl-12">
                <v-list-item-title>Con Letra</v-list-item-title>

                <v-list-item-icon>
                    <v-icon v-if="ObjetoUnity.CartillaFonetica!=2">mdi-checkbox-blank-circle-outline</v-icon>
                    <v-icon v-if="ObjetoUnity.CartillaFonetica==2" color="green darken-2">mdi-checkbox-marked-circle</v-icon>
                </v-list-item-icon>
            </v-list-item>
        </v-list-group>
    </v-list>
    <v-divider></v-divider>
    <v-list>
        <v-list-group no-action :value="prueba" @click="modificarTarjeta(estadoTarjetaFonetica?0:'')">
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon>mdi-image-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                <v-list-item-title>Tarjeta fonética</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item @click="modificarTarjeta(1)" class="pl-12">
                <v-list-item-title>Con Imagen</v-list-item-title>
                <v-list-item-icon>
                    <v-icon v-if="ObjetoUnity.TarjetaFonetica!=1">mdi-checkbox-blank-circle-outline</v-icon>
                    <v-icon v-if="ObjetoUnity.TarjetaFonetica==1" color="green darken-2">mdi-checkbox-marked-circle</v-icon>
                </v-list-item-icon>
            </v-list-item>
            <v-list-item @click="modificarTarjeta(2)" class="pl-12">
                <v-list-item-title>Con Letra</v-list-item-title>

                <v-list-item-icon>
                    <v-icon v-if="ObjetoUnity.TarjetaFonetica!=2">mdi-checkbox-blank-circle-outline</v-icon>
                    <v-icon v-if="ObjetoUnity.TarjetaFonetica==2" color="green darken-2">mdi-checkbox-marked-circle</v-icon>
                </v-list-item-icon>
            </v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <v-list-item @click="CambiarEstadoVideo()">
            <v-list-item-icon>
                <v-icon>mdi-account-voice</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Video articulación de fonemas</v-list-item-title>
            <v-list-item-icon>
                <v-icon v-if="ObjetoUnity.VideoArticulacion==0">mdi-checkbox-blank-circle-outline</v-icon>
                <v-icon v-if="ObjetoUnity.VideoArticulacion==1" color="green darken-2">mdi-checkbox-marked-circle</v-icon>
            </v-list-item-icon>
        </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list>
        <v-list-group no-action :value="prueba">
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon>mdi-shuffle-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                <v-list-item-title>Reproducción</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item @click="cambiarTipoReproduccion(0)" class="pl-12">
                <v-list-item-title>Larga</v-list-item-title>
                <v-list-item-icon>
                    <v-icon v-if="ObjetoUnity.TipoReproduccion!=0">mdi-checkbox-blank-circle-outline</v-icon>
                    <v-icon v-if="ObjetoUnity.TipoReproduccion==0" color="green darken-2">mdi-checkbox-marked-circle</v-icon>
                </v-list-item-icon>
            </v-list-item>
            <v-list-item @click="cambiarTipoReproduccion(1)" class="pl-12">
                <v-list-item-title>Corta</v-list-item-title>

                <v-list-item-icon>
                    <v-icon v-if="ObjetoUnity.TipoReproduccion!=1">mdi-checkbox-blank-circle-outline</v-icon>
                    <v-icon v-if="ObjetoUnity.TipoReproduccion==1" color="green darken-2">mdi-checkbox-marked-circle</v-icon>
                </v-list-item-icon>
            </v-list-item>
            <v-list-item @click="cambiarTipoReproduccion(2)" class="pl-12">
                <v-list-item-title>Individual</v-list-item-title>

                <v-list-item-icon>
                    <v-icon v-if="ObjetoUnity.TipoReproduccion!=2">mdi-checkbox-blank-circle-outline</v-icon>
                    <v-icon v-if="ObjetoUnity.TipoReproduccion==2" color="green darken-2">mdi-checkbox-marked-circle</v-icon>
                </v-list-item-icon>
            </v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <v-list-item @click="CambiarEstadoReproduccionPausa()">
            <v-list-item-icon>
                <v-icon>mdi-motion-pause-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Reproducción con pausa</v-list-item-title>
            <v-list-item-icon>
                <v-icon v-if="ObjetoUnity.TipoPausaReproduccion==0">mdi-checkbox-blank-circle-outline</v-icon>
                <v-icon v-if="ObjetoUnity.TipoPausaReproduccion==1" color="green darken-2">mdi-checkbox-marked-circle</v-icon>
            </v-list-item-icon>
        </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list>
        <v-list-group no-action>
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon>mdi-image-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                <v-list-item-title>TEKS</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-group no-action sub-group class="small-letter">
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-icon>mdi-image-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                    <v-list-item-title>KINDER</v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item class="pl-12">
                    <v-list-item-content>
                        K.A Demonstrate Phonological awareness by:
                        <ul>
                            <li>K.1.A(ii) recognizing spoken alliteration or groups of same simple syllable or initial sound.</li>
                            <li>K.1.A (vii) identifying initial and final sounds in simple words;</li>
                            <li>K.1.C (i) spelling common letter and sound correlations;</li>
                            <li>K.1.C (ii) spelling words with common syllabic pattern such as CV, VC, CCV, CVC, VCV, CVCV, CCVCV, and CVCCV</li>
                        </ul>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
            <v-list-group no-action sub-group>
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-icon>mdi-image-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                    <v-list-item-title>FIRST GRADE</v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item class="pl-12">
                    <v-list-item-content>
                        1.A Demonstrate Phonological awareness by:
                        <ul>
                            <li>1.1.A(ii) recognizing spoken alliteration or groups of same simple syllable or initial sound.</li>
                            <li>1.1.B (i) Identifying and matching sounds to individual letters;</li>
                            <li>1.1.B (ii) Decoding words with silabas trabadas such as /bla/, /bra/, and /gla/ digraphs; and words with multiple sound spelling patterns such as c, k, and q and s, z soft c and x;</li>
                            <li>1.1.B (iii) decoding words with silent h and words that use the syllables que - qui;</li>
                        </ul>
                        K.1.C demonstrate and apply spelling knowledge by:
                        <ul>
                            <li>1.1.C (i) spelling common letter and sound correlations </li>
                            <li>1.1.C (ii) spelling words with common pattern such as CV, VC, CCV, CVC, VCV, CVCV, CCVCV, and CVCCV;</li>
                        </ul>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
        </v-list-group>
    </v-list>
    </v-card>
  </div>
</template>

<script>

export default {
    name: "MissionOne",
    props: {
        volumen:Number
    },
    data(){
        return  {
            prueba:true,
            sidebarHeigth: '700px',
            collapsef: false,
            totalWidth: 0,
            webgl: true,
            customColor: "",
            btnSelectColor: true,
            loadUnity: false,
            //
            ObjetoUnity:{
                CartillaFonetica:1,//0=ocultado,1=mostradocon Imagen,2=mostradoConLetra
                TarjetaFonetica:1,//0=ocultado,1=mostradocon Imagen,2=mostradoConLetra
                VideoArticulacion:1,
                TipoReproduccion:0,
                TipoPausaReproduccion:0,
                ImagenDeFondo:0,
                ColorFondo:0
            },
            estadoCartillaFonetica:true,
            estadoTarjetaFonetica:true,
            estadoReproduccion:true,
            volumenLocal:0
        }
    },
    created(){
        this.volumenLocal=this.volumen;
    },
    methods: {
        modificarSonido(nuevoVolumen,tipoCambio){
            if(tipoCambio){//en este caso le agregamos al volumen
                let volumenSumado=this.volumenLocal+nuevoVolumen;
                if(volumenSumado<0 || volumenSumado>100){
                    return;
                }
                this.volumenLocal=volumenSumado;
            }else{//en este caso reemplazamos  el valor
                this.volumenLocal=nuevoVolumen;
            }
            this.$emit('modificarVolumen',  this.volumenLocal);
        },
        modificarCartilla(valorNuevo){
            console.log(valorNuevo);
            switch (valorNuevo) {
                case ""://significa que se acomoda a la tarjeta si esta activo o caso contrario se activaen 1
                    this.estadoCartillaFonetica=true;
                    if(this.estadoTarjetaFonetica){
                        this.ObjetoUnity.CartillaFonetica=this.ObjetoUnity.TarjetaFonetica;
                    }else{
                        this.ObjetoUnity.CartillaFonetica=1;
                    }
                    break;
                case 0:
                    this.estadoCartillaFonetica=false;
                    this.ObjetoUnity.CartillaFonetica=0;
                    break;
                case 1:
                    this.estadoCartillaFonetica=true;
                    this.ObjetoUnity.CartillaFonetica=1;
                    if(this.estadoTarjetaFonetica){
                        this.ObjetoUnity.TarjetaFonetica=1;
                    }
                    break;
                case 2:
                    this.estadoCartillaFonetica=true;
                    this.ObjetoUnity.CartillaFonetica=2;
                    if(this.estadoTarjetaFonetica){
                        this.ObjetoUnity.TarjetaFonetica=2;
                    }
                    break;
            }
            this.$emit('unityAction',  this.ObjetoUnity);
        },
        modificarTarjeta(valorNuevo){
            console.log(valorNuevo);
            switch (valorNuevo) {
                case ""://significa que se acomoda a la cartilla si esta activo o caso contrario se activaen 1
                    this.estadoTarjetaFonetica=true;
                    if(this.estadoCartillaFonetica){
                        this.ObjetoUnity.TarjetaFonetica=this.ObjetoUnity.CartillaFonetica;
                    }else{
                        this.ObjetoUnity.TarjetaFonetica=1;
                    }
                    break;
                case 0:
                    this.estadoTarjetaFonetica=false;
                    this.ObjetoUnity.TarjetaFonetica=0;
                    break;
                case 1:
                    this.estadoTarjetaFonetica=true;
                    this.ObjetoUnity.TarjetaFonetica=1;
                    if(this.estadoCartillaFonetica){
                        this.ObjetoUnity.CartillaFonetica=1;
                    }
                    break;
                case 2:
                    this.estadoTarjetaFonetica=true;
                    this.ObjetoUnity.TarjetaFonetica=2;
                    if(this.estadoCartillaFonetica){
                        this.ObjetoUnity.CartillaFonetica=2;
                    }
                    break;
            }
            //console.log(this.ObjetoUnity);
            this.$emit('unityAction',  this.ObjetoUnity);
        },
        CambiarEstadoVideo(){
            if(this.ObjetoUnity.VideoArticulacion==0){
                this.ObjetoUnity.VideoArticulacion=1;
            }else{
                this.ObjetoUnity.VideoArticulacion=0;
            }
            this.$emit('unityAction',  this.ObjetoUnity);
        },
        cambiarTipoReproduccion(tipoReproduccion){
            console.log(tipoReproduccion);
             this.ObjetoUnity.TipoReproduccion=tipoReproduccion;
            this.$emit('unityAction',  this.ObjetoUnity);
        },
        CambiarEstadoReproduccionPausa(){
            if(this.ObjetoUnity.TipoPausaReproduccion==0){
                this.ObjetoUnity.TipoPausaReproduccion=1;
            }else{
                this.ObjetoUnity.TipoPausaReproduccion=0;
            }
            this.$emit('unityAction',  this.ObjetoUnity);
        },
    },
    mounted() {
        const auxheight = window.innerHeight-50;
        this.sidebarHeigth = auxheight+'px';
    },
    computed:{
    },
}
</script>


<style lang="scss" scoped>
    .unity {
        .unity-container {
            canvas {
                height: 90vh;
            }
        }
    }
    $hoverSelected: #b3e7f2;
    #difuminado{
        background-image: url('../../assets/admin/background-difuminado.jpg');
        background-size: cover;
    }
    .footer{
        display:none;
    }
    .webgl-content{
        left: 0 !important;
        position: relative !important;
        transform: none !important;
        height: 90vh;
    }
    .webgl-inner{
        position: relative;
        right: 0;
        bottom: 0;
        top: 0;
        margin-top:0;
        width: 100%;
    }
    .mostrar-scroll{
        overflow-y:scroll;
    }
    .small-letter{
        font-size: 0.9em;
    }
</style>
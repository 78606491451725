<template>
  <!-- <div>
    <unity src="/unity/Build/20210414.json" width="1000" height="600" unityLoader="/unity/Build/UnityLoader.js"></unity>
  </div> -->
  <div class="caja">
    <div id="box-loader" class="d-none">
        <div class="background-loading justify-center">
            <div class="loader-bar" >
            <progress id="unityLoaderBar" value="0.5" max="1"></progress>
            </div>
        </div>
    </div>
    <v-row>
        <v-col class="col-3 pb-0 pl-2 pr-0" style="max-width: 22%;">
            <div class="position-relative " v-bind:class="{ bloqueo: enProceso }" >
                <div class="position-absolute-spiner" >
                    <v-progress-circular indeterminate color="primary" v-if="enProceso"></v-progress-circular>
                </div>
                <div class="bloqueo" id="BloquoInicial">
                    <component :is="currentRoute" @unityAction="sendData" @modificarVolumen="ModificarVolumen" v-bind:volumen="GeneralObjectUnity.AudioVolumen" />
                </div>
          </div>
        </v-col>
        <v-col class="pb-0 pl-0">
            <div class="webgl-inner">
                <unity v-if="loadUnity" src="/SE/Build/20210414.json" 
                    width="90%" :height="pruebaHeigth"
                    :hideFooter="true"
                    :external-progress="true"
                    unityLoader="/unity/Build/UnityLoader.js"
                    ref="unityInstance"
                ></unity>                    
            </div>
        </v-col>    
    </v-row>
      <div class="all-center" :class="loading ? 'box-loading' : 'd-none'">
          <div>
              <div :class="loading ? 'd-block' : 'd-none'" class="loader"></div>
              <h4>Loading...</h4>
          </div>
      </div>
  </div>
</template>

<script>
// import Unity from 'vue-unity-webgl'

// export default {
//   components: { Unity }
// }
import Unity from 'vue-unity-webgl'
import MissionOne from '../../../components/tool/MissionOne.vue'
import MissionTwo from '../../../components/tool/MissionTwo.vue'
import MissionThree from '../../../components/tool/MissionThree.vue'

export default {
    name: "TeachersTool",
    components: { Unity},
    created(){
        this.mission = this.$route.params.mission;
        let myWidth=innerWidth;
        let myHeigth=innerHeight;
        if (myHeigth<1200){
            this.pruebaHeigth=myHeigth-50
        }
        // document.getElementById("unityContainer").style.width=myWidth+"px";
        // document.getElementById("unityContainer").style.height=myHeigth+"px";
    },
    mounted(){
        this.loadUnity = true;

        this.totalWidth = window.screen.width;
        let mission = this.$route.params.mission;


        let loading = this.$route.params.loading;

    },
    data(){
        return {
            progress: 0,
            loading: false,
            mission: "",
            collapsef: false,
            totalWidth: 0,
            volumen: 50,
            webgl: true,
            customColor: "",
            btnSelectColor: true,
            loadUnity: false,
            //nueva forma
            GeneralObjectUnity:{
                AudioVolumen:50,
                ImagenDeFondo:0,//0=selva,1=solido,2=fondoIconos
                ColorFondo:''
            },
            routing: true,
            enProceso:false,
            pruebaHeigth:1100
        }
    },
    methods: {
        ProbarClick(){
            this.$refs.unityInstance.SetFullscreen(1);
        },
        ModificarVolumen(volumen){
            this.GeneralObjectUnity.AudioVolumen=volumen;
            this.EnviarObjetoGeneral();
        },
        sendData(valueToSend){
            console.log(valueToSend);
            this.enProceso=true;
            this.$refs.unityInstance.message("GestorTE", "WebGLRecepcionParametros", JSON.stringify(valueToSend));
            //setTimeout(function(){ this.enProceso=false; },5000);
            setTimeout(() => this.enProceso=false, 700);
        },
        cambiarEscena(parametersEscene){
            console.log(parametersEscene);
            this.$refs.unityInstance.message("GestorTE", "WebGLMisionActividad", parametersEscene);
        },
        EnviarObjetoGeneral(){
            console.log(this.GeneralObjectUnity);
            this.$refs.unityInstance.message("GestorTE", "WebGLRecepcionParametrosGenerales", JSON.stringify(this.GeneralObjectUnity));
        },
        SendGeneralObject(){

        },
        selectColor(val, elem){
            let actives = document.getElementsByClassName('modal-active');

            actives.forEach(elem => {
                elem.classList.remove('modal-active');
            });
            
            elem.target.classList.add('modal-active')
            
            if(typeof(val)=='number'){
                console.log("es numero");
                this.GeneralObjectUnity.ImagenDeFondo=val;
            }else{
                
                this.GeneralObjectUnity.ImagenDeFondo=1;//cambiar esto por la opcion cuando sea numero
                this.GeneralObjectUnity.ColorFondo=val;
            }
            this.btnSelectColor = false;
        },
        sendColor(){
            // console.log("fondo_"+this.customColor);
            // let colorLocal = "fondo_"+this.customColor;
            // this.sendData(colorLocal.toString());
            this.EnviarObjetoGeneral();
            this.$bvModal.hide('modal-configurar');
        },
        someAction(){
            console.log("Ya el componente hijo está cargado");
        }
    },
    computed:{
        currentRoute(){
            return this.$route.params.mission
        },
    },
    watch: {
        currentRoute(){
            let mission = this.$route.params.mission;
            if(mission == 'MissionOne') this.cambiarEscena('1 1')
            if(mission == 'MissionTwo') this.cambiarEscena('2 1')
            if(mission == 'MissionThree') this.cambiarEscena('3 1')
        },
        '$refs.unityInstance.gameInstance':{
            handler(val){
                console.log(val);
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
    .box-loading{
        width: 100%;
        height: 100vh;
        background-color: white;
        position: fixed;
        top: 0;
    }
    .loader,
    .loader:after {
    border-radius: 50%;
    width: 15em;
    height: 15em;
    }
    .loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(201, 201, 201, 0.61);
    border-right: 1.1em solid rgba(201, 201, 201, 0.61);
    border-bottom: 1.1em solid rgba(201, 201, 201, 0.61);
    border-left: 1.1em solid #2c2c2c;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    }
    @-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    }
    @keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    }

    .unity {
        .unity-container {
            canvas {
                height: 90vh;
            }
        }
    }
    $hoverSelected: #b3e7f2;
    #mision1{
        background-image: url('../../../assets/admin/background-difuminado.jpg');
        background-size: cover;
    }
    .footer{
        display:none;
    }
    .modal-color{
        width:150px;
        height:100px;
        border: 1px solid rgb(172, 170, 170);
        cursor: pointer;
        transition: .5s;
    }
    div.modal-color:hover{
        transition: .5s;
        border: 5px solid rgb(179, 179, 179);
    }
    .modal-active{
        border: 6px solid rgb(172, 170, 170);
    }
    .webgl-content{
        left: 0 !important;
        position: relative !important;
        transform: none !important;
        height: 90vh;
    }
    .webgl-inner{
        position: relative;
        right: 0;
        bottom: 0;
        top: 0;
        margin-top:0;
        width: 100%;
    }
    .caja{
        display: flex;
        flex-direction: column;
        height: 100% !important;
    }
    .contenedor{
        height: 92vh !important;
    }
    .inner-collapsible div{
        padding: 7px 15px 7px 45px;
        border-top: 1px solid #5fc2d6;
    }
    .inner-collapsible div:last-child{
        border-bottom: 1px solid #5fc2d6;
    }
    .inner-collapsible div:hover{
        background-color: #5fc2d6;
    }
    .tools{
        position: relative;
        bottom: 0;
        max-height: 98vh;
    }
    .tool-list{
        list-style: none;
        text-align: left !important;
    }
    
    .tool-list .item-list{
        text-align: left !important;
        padding: 20px 7px 22px 20px;
        cursor: pointer;
        color: black !important;
        border-radius: 0;
        margin-right: 0;
    }
    .tool-list .item-list:hover{
        background-color: $hoverSelected;
    }
    .collapsible-item{
        color: rgb(20, 20, 20);
    }
    .item-active{
        background-color: $hoverSelected;
        transition: .5s;
        cursor: pointer;
    }
    .disabled-li:hover{
        background-color: transparent !important;
        cursor: auto;
    }
    .tool-list img {
        width: 17px;
        margin-right: 14px;
    }
    .img-icon img{
        width: 17px;
    }
    .top{
        border-bottom: 0.5px solid rgb(211, 211, 211);
    }
    .selected{
        background-color: $hoverSelected;
    }
    //clases para el spinner
    .position-relative{
        position:relative;
        
    }
    .bloqueo{
        opacity: 0.4;
        pointer-events: none;
    }
    .position-absolute-spiner{

         position: absolute;
         top: 50%;
         left: 50%;
    }
    .background-loading{
    width: 80%;
    height: calc(100% - 50px);
    background-image: url('/loadingimg.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: fixed;
    right: 0;
    top: 45px;
    display: flex;
    z-index: 1;
}
.loader-bar{
  position: absolute;
  bottom: 50px;
}
.loader-bar > progress{
    border: 5px solid white;
    border-radius: 5px;
    width: 250px;
    box-shadow: 0 2px 3px rgba(0,0,0,.5) inset;
  }
  progress::-webkit-progress-value {background-color: #e92f00 !important;}
  progress::-moz-progress-bar {background-color: #e92f00 !important;}
</style>
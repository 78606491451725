<template>
  <div>
    <v-card class="mostrar-scroll" :height="sidebarHeigth">
        <v-list>
            <v-list-item>
                <v-list-item-title>Opciones de uso</v-list-item-title>
            </v-list-item>
            <v-list-item >
                <v-list-item-icon>
                    <v-icon>mdi-volume-high</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Volumen</v-list-item-title>
            </v-list-item>
            <v-list-item >
                <v-list-item-title class="mx-5">
                    <v-slider v-model.number="volumenLocal" append-icon="mdi-volume-plus" prepend-icon="mdi-volume-minus" @click:append="modificarSonido(5,true)" @click:prepend="modificarSonido(-5,true)"></v-slider>
                </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="SeleccionImagen()">
                <v-list-item-icon>
                    <v-icon>mdi-account-voice</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Imagen</v-list-item-title>
                <v-list-item-icon>
                    <v-icon v-if="ObjetoUnity.Imagen==0">mdi-checkbox-blank-circle-outline</v-icon>
                    <v-icon v-if="ObjetoUnity.Imagen==1" color="green darken-2">mdi-checkbox-marked-circle</v-icon>
                </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item >
                <v-list-item-icon>
                    <v-icon>mdi-volume-high</v-icon>
                </v-list-item-icon>
                <v-list-item-title># Reproducciones de Sonido</v-list-item-title>
            </v-list-item>
            <v-list-item >
                <v-list-item-title class="mx-10">
                    <v-text-field v-model.number="ObjetoUnity.NumReproduccionSonido" filled append-outer-icon="mdi-plus-thick" prepend-icon="mdi-minus-thick" @click:append-outer="modificarNumReproduccionSonido(1)" @click:prepend="modificarNumReproduccionSonido(-1)"></v-text-field>
                </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item >
                <v-list-item-icon>
                    <v-icon>mdi-volume-high</v-icon>
                </v-list-item-icon>
                <v-list-item-title># Reproducciones Deletreo</v-list-item-title>
            </v-list-item>
            <v-list-item >
                <v-list-item-title class="mx-10">
                    <v-text-field v-model.number="ObjetoUnity.NumReproduccionDeletreo" filled append-outer-icon="mdi-plus-thick" prepend-icon="mdi-minus-thick" @click:append-outer="modificarNumReproduccionDeletreo(1)" @click:prepend="modificarNumReproduccionDeletreo(-1)"></v-text-field>
                </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item >
                <v-list-item-icon>
                    <v-icon>mdi-volume-high</v-icon>
                </v-list-item-icon>
                <v-list-item-title># Reproducciones Silabeo</v-list-item-title>
            </v-list-item>
            <v-list-item >
                <v-list-item-title class="mx-10">
                    <v-text-field v-model.number="ObjetoUnity.NumReproduccionSilabeo" filled append-outer-icon="mdi-plus-thick" prepend-icon="mdi-minus-thick" @click:append-outer="modificarNumReproduccionSilabeo(1)" @click:prepend="modificarNumReproduccionSilabeo(-1)"></v-text-field>
                </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item >
                <v-list-item-icon>
                    <v-icon>mdi-volume-high</v-icon>
                </v-list-item-icon>
                <v-list-item-title># Reproduccion Palabra</v-list-item-title>
            </v-list-item>
            <v-list-item >
                <v-list-item-title class="mx-10">
                    <v-text-field v-model.number="ObjetoUnity.NumReproduccionPalabras" filled append-outer-icon="mdi-plus-thick" prepend-icon="mdi-minus-thick" @click:append-outer="modificarNumReproduccionPalabras(1)" @click:prepend="modificarNumReproduccionPalabras(-1)"></v-text-field>
                </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="modificarReproduccionPausa()">
                <v-list-item-icon>
                    <v-icon>mdi-motion-pause-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Reproduccion con Pausa</v-list-item-title>
                <v-list-item-icon>
                    <v-icon v-if="ObjetoUnity.TipoPausaReproduccion==0">mdi-checkbox-blank-circle-outline</v-icon>
                    <v-icon v-if="ObjetoUnity.TipoPausaReproduccion==1" color="green darken-2">mdi-checkbox-marked-circle</v-icon>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
            <v-list-group no-action>
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-icon>mdi-image-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                    <v-list-item-title>TEKS</v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-group no-action sub-group class="small-letter">
                    <template v-slot:activator>
                        <v-list-item-icon>
                            <v-icon>mdi-image-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title>KINDER</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item class="pl-12">
                        <v-list-item-content>
                            K.A Demonstrate Phonological awareness by: 
                            <ul>
                                <li>K.1.A iii identifying the individual words in a spoken sentence</li>
                                <li>K.1.A (v) blending syllables to form multisyllabic words</li>
                                <li>K.1.A (vi) segmenting multisyllabic words into syllables</li>
                                <li>K.1.A (ix) manipulating syllables within a multisyllabic word</li>
                            </ul>
                            K.1.B demonstrate and apply phonemic knowledge by:
                            <ul>
                                <li>K.1.B (ii) Using letter-sound relationship to decode one and two syllables words and multisyllabic words, including CV, VC, CCV, CVC, VCV, CVCV, CCVCV, and CVCCV</li>
                                <li>K.1.B (iii) decoding words with silent h and consonant digraphs such as /ch/ /rr/, and /ll/</li>
                                <li>K.1.B (iv) recognizing that new words are created when syllables are changes, added or deleted</li>
                                <li>K.1.C demonstrate and apply spelling knowledge by:</li>
                            </ul>
                            K.1.C demonstrate and apply spelling knowledge by:
                            <ul>
                                <li>K.1.C (i) spelling common letter and sound correlations</li>
                                <li>K.1.C (ii) spelling words with common syllabic pattern such as CV, VC, CCV, CVC, VCV, CVCV, CCVCV, and CVCCV</li>
                            </ul>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-group no-action sub-group>
                    <template v-slot:activator>
                        <v-list-item-icon>
                            <v-icon>mdi-image-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title>FIRST GRADE</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item class="pl-12">
                        <v-list-item-content>
                            1.A Demonstrate Phonological awareness by:
                            <ul>
                                <li>1.1.A (iii) recognizing the change in spoken words when a specified syllable is added, changed, or removed</li>
                                <li>1.1.A (iv) segmenting spoken words into individual syllables</li>
                                <li>1.1.A (v) blending spoken complex syllables including silabas trabadas to form multisyllabic words</li>
                                <li>1.1.A (vi) segmenting spoken words into syllables including words with silabas trabadas</li>
                                <li>1.1.A (vii) manipulating syllables within words</li>
                            </ul>
                            1.1.B demonstrate and apply phonemic knowledge by:
                            <ul>
                                <li>1.1.B (i) Identifying and matching sounds to individual letters</li>
                                <li>1.1.B (ii) Decoding words with silabas trabadas such as /bla/, /bra/, and /gla/ digraphs; and words with multiple sound spelling patterns such as c, k, and q and s, z soft c and x</li>
                                <li>1.1.B (iii) decoding words with silent h and words that use the syllables que - qui </li>
                                <li>1.1.B (vi) decoding three- to four-syllable words</li>
                            </ul>
                            1.1.C demonstrate and apply spelling knowledge by:
                            <ul>
                                <li>1.1.C (i) spelling common letter and sound correlations </li>
                                <li>1.1.C (ii) spelling words with common pattern such as CV, VC, CCV, CVC, VCV, CVCV, CCVCV, and CVCCV</li>
                                <li>1.1.C (iii) spelling words with silent h; consonant digraphs such as /ch/, /rr/, and /ll/; and silabas trabadas such as  /bla/, /bra/, and /gla/, and /gra/</li>
                                <li>1.1.C (iv) spelling multisyllabic words including words with que-, qui-</li>
                            </ul>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list-group>
        </v-list>
    </v-card>
  </div>
</template>

<script>

export default {
    name: "MissionThree",
    data(){
        return  {
            collapsef: false,
            sidebarHeigth: '700px',
            totalWidth: 0,
            volumen: 50,
            webgl: true,
            customColor: "",
            btnSelectColor: true,
            loadUnity: false,
            //
            volumenLocal:0,
            ObjetoUnity:{
                Imagen:1,//0=desactivado,1=todo,2=etapas,3=grafemasCartillaSilabas
                NumReproduccionSonido:0,//
                NumReproduccionSilabeo:1,
                NumReproduccionDeletreo:0,
                NumReproduccionPalabras:1,
                TipoPausaReproduccion:0
            },
            estadoSpinner: true
        }
    },
    created(){
        this.volumenLocal=this.volumen;
    },
    methods: {
        modificarSonido(nuevoVolumen,tipoCambio){
            if(tipoCambio){//en este caso le agregamos al volumen
                let volumenSumado=this.volumenLocal+nuevoVolumen;
                if(volumenSumado<0 || volumenSumado>100){
                    return;
                }
                this.volumenLocal=volumenSumado;
            }else{//en este caso reemplazamos  el valor
                this.volumenLocal=nuevoVolumen;
            }
            this.$emit('modificarVolumen',  this.volumenLocal);
        },
        SeleccionImagen(){
            if(this.ObjetoUnity.Imagen==0){
                this.ObjetoUnity.Imagen=1;
            }else{
                this.ObjetoUnity.Imagen=0;
            }
            this.$emit('unityAction',  this.ObjetoUnity);
        },
        modificarNumReproduccionSonido(agregado){    
            let total=this.ObjetoUnity.NumReproduccionSonido+agregado;
            if(total<0 || total>3){
                return;
            }
            this.ObjetoUnity.NumReproduccionSonido=total;

            this.$emit('unityAction',  this.ObjetoUnity);
        },
        modificarNumReproduccionSilabeo(agregado){
            let total=this.ObjetoUnity.NumReproduccionSilabeo+agregado;
            if(total<0 || total>3){
                return;
            }
            this.ObjetoUnity.NumReproduccionSilabeo=total;

            this.$emit('unityAction',  this.ObjetoUnity);
        },
        modificarNumReproduccionDeletreo(agregado){
            let total=this.ObjetoUnity.NumReproduccionDeletreo+agregado;
            if(total<0 || total>3){
                return;
            }
            this.ObjetoUnity.NumReproduccionDeletreo=total;

            this.$emit('unityAction',  this.ObjetoUnity);
        },
        modificarNumReproduccionPalabras(agregado){
            let total=this.ObjetoUnity.NumReproduccionPalabras+agregado;
            if(total<0 || total>3){
                return;
            }
            this.ObjetoUnity.NumReproduccionPalabras=total;

            this.$emit('unityAction',  this.ObjetoUnity);
        },
        modificarReproduccionPausa(){
            if(this.ObjetoUnity.TipoPausaReproduccion==0){
                this.ObjetoUnity.TipoPausaReproduccion=1;
            }else{
                this.ObjetoUnity.TipoPausaReproduccion=0;
            }
            this.$emit('unityAction',  this.ObjetoUnity);
        },
    },
    mounted() {
        const auxheight = window.innerHeight-50;
        this.sidebarHeigth = auxheight+'px';
    },
    computed:{
    },
}
</script>


<style lang="scss" scoped>
    .unity {
        .unity-container {
            canvas {
                height: 90vh;
            }
        }
    }
    $hoverSelected: #b3e7f2;
    
    #difuminado{
        background-image: url('../../assets/admin/background-difuminado.jpg');
        background-size: cover;
    }
    .footer{
        display:none;
    }
    .modal-color{
        width:250px;
        height:150px;
        border: 1px solid rgb(172, 170, 170);
        cursor: pointer;
        transition: .5s;
    }
    div.modal-color:hover{
        transition: .5s;
        border: 5px solid rgb(179, 179, 179);
    }
    .modal-active{
        border: 6px solid rgb(172, 170, 170);
    }
    .webgl-content{
        left: 0 !important;
        position: relative !important;
        transform: none !important;
        height: 90vh;
    }
    .webgl-inner{
        position: relative;
        right: 0;
        bottom: 0;
        top: 0;
        margin-top:0;
        width: 100%;
    }

    .spinner-ajuste-horizontal{
        width: 150px;
        text-align: center;
    }
    .mostrar-scroll{
        overflow-y:scroll;
    }
    .small-letter{
        font-size: 0.9em;
    }
</style>
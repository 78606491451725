<template>
  <div>
    <v-card class="mostrar-scroll" :height="sidebarHeigth">
        <v-list>
            <v-list-item>
                <v-list-item-title>Opciones de uso</v-list-item-title>
            </v-list-item>
            <v-list-item >
                <v-list-item-icon>
                    <v-icon>mdi-volume-high</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Volumen</v-list-item-title>
            </v-list-item>
            <v-list-item >
                <v-list-item-title class="mx-5">
                    <v-slider v-model.number="volumenLocal" append-icon="mdi-volume-plus" prepend-icon="mdi-volume-minus" @click:append="modificarSonido(5,true)" @click:prepend="modificarSonido(-5,true)"></v-slider>
                </v-list-item-title>
            </v-list-item>
            <v-list-group no-action :value="true" @click="CaambiarTablaSilabas(estadoTablaSilabas?0:1)" color="primary">
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-icon>mdi-book-music-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                    <v-list-item-title>Tabla de sílabas directas</v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item @click="CaambiarTablaSilabas(1)" class="pl-12">
                    <v-list-item-title>Todo</v-list-item-title>

                    <v-list-item-icon>
                        <v-icon v-if="ObjetoUnity.CartillaSilabas!=1">mdi-checkbox-blank-circle-outline</v-icon>
                        <v-icon v-if="ObjetoUnity.CartillaSilabas==1" color="green darken-2">mdi-checkbox-marked-circle</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-list-item @click="CaambiarTablaSilabas(2)" class="pl-12">
                    <v-list-item-title>Etapas</v-list-item-title>

                    <v-list-item-icon>
                        <v-icon v-if="ObjetoUnity.CartillaSilabas!=2">mdi-checkbox-blank-circle-outline</v-icon>
                        <v-icon v-if="ObjetoUnity.CartillaSilabas==2" color="green darken-2">mdi-checkbox-marked-circle</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list-group>
            <v-divider></v-divider>
            <v-list-item @click="FormandoSilabas()">
                <v-list-item-icon>
                    <v-icon>mdi-account-voice</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Formando sílabas directas</v-list-item-title>
                <v-list-item-icon>
                    <v-icon v-if="ObjetoUnity.TipoBlending==0">mdi-checkbox-blank-circle-outline</v-icon>
                    <v-icon v-if="ObjetoUnity.TipoBlending==1" color="green darken-2">mdi-checkbox-marked-circle</v-icon>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
            <v-list-group no-action :value="true">
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-icon>mdi-shuffle-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                    <v-list-item-title>Reproducción</v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item @click="modificarReproduccion(0)" class="pl-12">
                    <v-list-item-title>Formando Silabas con Sonidos</v-list-item-title>
                    <v-list-item-icon>
                        <v-icon v-if="ObjetoUnity.TipoReproduccion!=0">mdi-checkbox-blank-circle-outline</v-icon>
                        <v-icon v-if="ObjetoUnity.TipoReproduccion==0" color="green darken-2">mdi-checkbox-marked-circle</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-list-item @click="modificarReproduccion(1)" class="pl-12">
                    <v-list-item-title>Sonidos Individuales</v-list-item-title>

                    <v-list-item-icon>
                        <v-icon v-if="ObjetoUnity.TipoReproduccion!=1">mdi-checkbox-blank-circle-outline</v-icon>
                        <v-icon v-if="ObjetoUnity.TipoReproduccion==1" color="green darken-2">mdi-checkbox-marked-circle</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-list-item @click="modificarReproduccion(2)" class="pl-12">
                    <v-list-item-title>Sílaba indivual</v-list-item-title>

                    <v-list-item-icon>
                        <v-icon v-if="ObjetoUnity.TipoReproduccion!=2">mdi-checkbox-blank-circle-outline</v-icon>
                        <v-icon v-if="ObjetoUnity.TipoReproduccion==2" color="green darken-2">mdi-checkbox-marked-circle</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list-group>
            <v-divider></v-divider>
            <v-list-item @click="modificarReproduccionPausa()">
                <v-list-item-icon>
                    <v-icon>mdi-motion-pause-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Reproduccion con Pausa</v-list-item-title>
                <v-list-item-icon>
                    <v-icon v-if="ObjetoUnity.TipoPausaReproduccion==0">mdi-checkbox-blank-circle-outline</v-icon>
                    <v-icon v-if="ObjetoUnity.TipoPausaReproduccion==1" color="green darken-2">mdi-checkbox-marked-circle</v-icon>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
            <v-list-group no-action>
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-icon>mdi-image-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                    <v-list-item-title>TEKS</v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-group no-action sub-group class="small-letter">
                    <template v-slot:activator>
                        <v-list-item-icon>
                            <v-icon>mdi-image-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title>KINDER</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item class="pl-12">
                        <v-list-item-content>
                            <ul>
                                <li>K.1.A (iv) identifying syllables in spoken words</li>
                                <li>K.1.A (v) blending syllables to form multisyllabic words</li>
                                <li>K.1.A (viii) blending spoken phonemes to form syllables</li>
                            </ul>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-group no-action sub-group>
                    <template v-slot:activator>
                        <v-list-item-icon>
                            <v-icon>mdi-image-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title>FIRST GRADE</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item class="pl-12">
                        <v-list-item-content>
                            1.A Demonstrate Phonological awareness by:
                            <ul>
                                <li>1.1.A (iv) segmenting spoken words into individual syllables</li>
                                <li>1.1.A (v) blending spoken complex syllables including silabas trabadas to form multisyllabic words</li>
                                <li>1.1.A (vi) segmenting spoken words into syllables including words with silabas trabadas</li>
                                <li>1.1.A (vii) manipulating syllables within words</li>
                            </ul>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list-group>
        </v-list>
    </v-card>
  </div>
</template>

<script>

export default {
    name: "MissionTwo",
    data(){
        return  {
            collapsef: false,
            sidebarHeigth: '700px',
            totalWidth: 0,
            volumen: 50,
            webgl: true,
            customColor: "",
            btnSelectColor: true,
            loadUnity: false,
            //
            volumenLocal:0,
            ObjetoUnity:{
                CartillaSilabas:1,//0=desactivado,1=todo,2=etapas,3=grafemasCartillaSilabas
                TipoBlending:1,//
                TipoReproduccion:1,
                TipoPausaReproduccion:0
            },
            estadoTablaSilabas:true,
            estadoReproduccion:true
        }
    },
    created(){
        this.volumenLocal=this.volumen;
    },
    methods: {
        modificarSonido(nuevoVolumen,tipoCambio){
            if(tipoCambio){//en este caso le agregamos al volumen
                let volumenSumado=this.volumenLocal+nuevoVolumen;
                if(volumenSumado<0 || volumenSumado>100){
                    return;
                }
                this.volumenLocal=volumenSumado;
            }else{//en este caso reemplazamos  el valor
                this.volumenLocal=nuevoVolumen;
            }
            this.$emit('modificarVolumen',  this.volumenLocal);
        },
        CaambiarTablaSilabas(nuevoValor){
            switch (nuevoValor) {
                case ""://significa que se acomoda a la tarjeta si esta activo o caso contrario se activaen 1
                    break;
                case 0:
                    this.estadoTablaSilabas=false;
                    this.ObjetoUnity.CartillaSilabas=0;
                    break;
                case 1:
                    this.estadoTablaSilabas=true;
                    this.ObjetoUnity.CartillaSilabas=1;
                    break;
                case 2:
                    this.estadoTablaSilabas=true;
                    this.ObjetoUnity.CartillaSilabas=2;
                    break;
            }
            this.$emit('unityAction',  this.ObjetoUnity);
        },
        FormandoSilabas(){
            if(this.ObjetoUnity.TipoBlending==0){
                this.ObjetoUnity.TipoBlending=1;
            }else{
                this.ObjetoUnity.TipoBlending=0;
            }
            this.$emit('unityAction',  this.ObjetoUnity);
        },
        modificarReproduccionPausa(){
            if(this.ObjetoUnity.TipoPausaReproduccion==0){
                this.ObjetoUnity.TipoPausaReproduccion=1;
            }else{
                this.ObjetoUnity.TipoPausaReproduccion=0;
            }
            this.$emit('unityAction',  this.ObjetoUnity);
        },
        modificarReproduccion(nuevoValor){
            this.ObjetoUnity.TipoReproduccion=nuevoValor;
            this.$emit('unityAction',  this.ObjetoUnity);
        }
    },
    mounted() {
        const auxheight = window.innerHeight-50;
        this.sidebarHeigth = auxheight+'px';
    },
    computed:{

    },
}
</script>


<style lang="scss" scoped>
    .unity {
        .unity-container {
            canvas {
                height: 90vh;
            }
        }
    }
    $hoverSelected: #b3e7f2;
    #difuminado{
        background-image: url('../../assets/admin/background-difuminado.jpg');
        background-size: cover;
    }
    .footer{
        display:none;
    }
    .modal-color{
        width:250px;
        height:150px;
        border: 1px solid rgb(172, 170, 170);
        cursor: pointer;
        transition: .5s;
    }
    div.modal-color:hover{
        transition: .5s;
        border: 5px solid rgb(179, 179, 179);
    }
    .modal-active{
        border: 6px solid rgb(172, 170, 170);
    }
    .webgl-content{
        left: 0 !important;
        position: relative !important;
        transform: none !important;
        height: 90vh;
    }
    .webgl-inner{
        position: relative;
        right: 0;
        bottom: 0;
        top: 0;
        margin-top:0;
        width: 100%;
    }
    .caja{
        display: flex;
        flex-direction: column;
        height: 100% !important;
    }
    .contenedor{
        height: 92vh !important;
    }
    .inner-collapsible div{
        padding: 7px 15px 7px 45px;
        border-top: 1px solid #5fc2d6;
    }
    .inner-collapsible div:last-child{
        border-bottom: 1px solid #5fc2d6;
    }
    .inner-collapsible div:hover{
        background-color: #5fc2d6;
    }
    .tools{
        position: relative;
        bottom: 0;
        max-height: 98vh;
        overflow-y: scroll;
    }
    .tool-list{
        list-style: none;
        text-align: left !important;
    }
    
    .tool-list .item-list{
        text-align: left !important;
        padding: 20px 7px 22px 20px;
        cursor: pointer;
        color: black !important;
        border-radius: 0;
        margin-right: 0;
    }
    .tool-list .item-list:hover{
        background-color: $hoverSelected;
    }
    .collapsible-item{
        color: rgb(20, 20, 20);
    }
    .item-active{
        background-color: $hoverSelected;
        transition: .5s;
        cursor: pointer;
    }
    .disabled-li:hover{
        background-color: transparent !important;
        cursor: auto;
    }
    .tool-list img {
        width: 17px;
        margin-right: 14px;
    }
    .img-icon img{
        width: 17px;
    }
    .top{
        border-bottom: 0.5px solid rgb(211, 211, 211);
    }
    .selected{
        background-color: $hoverSelected;
    }
    .mostrar-scroll{
        overflow-y:scroll;
    }
    .small-letter{
        font-size: 0.9em;
    }
</style>